define("discourse/plugins/discourse-patreon/discourse/templates/connectors/topic-above-footer-buttons/patreon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showDonationPrompt}}
    <div class="patreon-donation-prompt">
      <span role="button" class="close" {{action "close"}}>
        {{d-icon "times"}}
      </span>
  
      {{html-safe
        (i18n
          "patreon.donation_prompt.body"
          campaignUrl=this.siteSettings.patreon_donation_prompt_campaign_url
        )
      }}
    </div>
  {{/if}}
  */
  {
    "id": "AFl/JtZj",
    "block": "[[[41,[30,0,[\"showDonationPrompt\"]],[[[1,\"  \"],[10,0],[14,0,\"patreon-donation-prompt\"],[12],[1,\"\\n    \"],[11,1],[24,\"role\",\"button\"],[24,0,\"close\"],[4,[38,3],[[30,0],\"close\"],null],[12],[1,\"\\n      \"],[1,[28,[35,4],[\"times\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[1,[28,[35,5],[[28,[37,6],[\"patreon.donation_prompt.body\"],[[\"campaignUrl\"],[[30,0,[\"siteSettings\",\"patreon_donation_prompt_campaign_url\"]]]]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"span\",\"action\",\"d-icon\",\"html-safe\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-patreon/discourse/templates/connectors/topic-above-footer-buttons/patreon.hbs",
    "isStrictMode": false
  });
});